import Layout from '@/components/layout';
import Seo from '@/components/seo';
import Teaser from '@/components/teaser';
import { TagTemplateProps } from '@/types';
import { useStaticQuery, graphql } from 'gatsby';
import { values } from 'lodash';
import { ArticleList, Articles } from '../articles';

export default function TagPageTemplate(props: TagTemplateProps) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const siteTitle = site.siteMetadata?.title || 'Title';

  return (
    <Layout location={props.location} title={siteTitle}>
      <Seo title={props.title}></Seo>
      <h1>{props.title}</h1>
      <ol style={{ listStyleType: 'none' }}>
        {values(ArticleList).map(
          (articleId, index) =>
            Articles[articleId].tags.includes(props.tag) && (
              <li key={index}>
                <Teaser
                  date={new Date().toDateString()}
                  slug={articleId}
                  title={Articles[articleId].title}
                  description={Articles[articleId].description}
                />
              </li>
            ),
        )}
      </ol>
    </Layout>
  );
}
