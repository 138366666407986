import { Link } from 'gatsby';

type TeaserProps = {
  slug: string;
  title: string;
  date: string;
  description: string;
};

const Teaser = (props: TeaserProps) => {
  return (
    <article
      className="post-list-item"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header>
        <h2>
          <Link to={`/${props.slug}/`} itemProp="url">
            <span itemProp="headline">{props.title}</span>
          </Link>
        </h2>
        <small>{props.date}</small>
      </header>
      <section>
        <p
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
          itemProp="description"
        />
      </section>
    </article>
  );
};

export default Teaser;
